import {ThemeProvider as MaterialThemeProvider} from '@material-ui/core'
import React from 'react'
import lightTheme from '../light'

export const ThemeProvider: React.FC = ({children}) => {
  return (
    <MaterialThemeProvider theme={lightTheme}>{children}</MaterialThemeProvider>
  )
}

export default ThemeProvider
