import '@fontsource/rubik'
import '@fontsource/rubik/300.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/600.css'
import {createMuiTheme} from '@material-ui/core'
import {pxToRem} from '../../lib'

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#5887ff',
    },
    secondary: {
      main: '#fed766',
    },
    text: {
      primary: '#2d2d34',
    },
    background: {
      default: '#fcfdfd',
      paper: '#ffffff',
    },
  },
  typography: {
    h1: {
      fontSize: pxToRem(64),
      fontWeight: 'bold',
      lineHeight: '150%',
    },
    h2: {
      fontSize: pxToRem(48),
      fontWeight: 'bold',
      lineHeight: '150%',
    },
    h3: {
      fontSize: pxToRem(36),
      fontWeight: 'bold',
      lineHeight: '150%',
    },
    body1: {
      lineHeight: '150%',
    },
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontFamily: 'Rubik',
  },
})
export default lightTheme
