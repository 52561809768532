module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HypeSync: Software solutions for emerging businesses.","short_name":"HypeSync","start_url":"/","background_color":"#ffffff","theme_color":"#5887ff","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"be9ab492cb23f3fa168a20b1391bc8f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"3348869012","enableOnDevMode":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
